* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

body {
  background: #f3f5f9;
}

.wrapper {
  display: flex;
  position: relative;
}

.wrapper .sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  background: #082c3c;
  padding: 30px;
}

.wrapper .sidebar h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.wrapper .sidebar ul li {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(225, 225, 225, 0.5);
  padding-left: 10px;
}

.wrapper .sidebar ul li .link {
  color: #bdb8d7;
  display: inline;
  text-decoration: none !important;
  margin-top: -5px !important;
}

.wrapper .sidebar ul li:hover {
  background: #154e66;
}

.wrapper .sidebar ul li:hover .link {
  color: #fff;
}

.wrapper .sidebar ul li .link .fas {
  width: 25px;
  margin-right: 5px;
  margin-bottom: -5px;
  margin-top: -8px;
}

.logo {
  width: 90%;
  margin-bottom: 10px;
}