.background {
  background-image: url('../../assets/images/login_background.png');
  background-size: cover;                      
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.logo {
  width: 60%;
}

.btnStyle {
  background-color: #082c3c !important;
}