/* CSS Styles for the Age Legend */
.legend {
  padding-left: 10px;
  margin-left: 20%;
  height: 20px;
}

.legend h5 {
  margin-top: 0;
  font-size: 16px;
}

.legend-items {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 50%;
}

.legend-label {
  font-size: 14px;
}

.barchart {
  margin-left: 20%;
  margin-top: 50px;
}  