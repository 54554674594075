.dot {
  height: 15px;
  width: 15px;
  background-color: grey;
  border-radius: 50%;
  margin-top: 42px;
  position: absolute;
}

.box {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-right: 10px;
}

.line {
  border-left: 2px solid grey;
  height: 500px; 
  position: absolute;
  margin-left: 6px;
}

.line:nth-of-type(1) {
  margin-top: 50px;
}