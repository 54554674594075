.filterBox {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #F8F8F8;
  overflow-x: hidden;
  transition: 0.5s !important;
  padding-top: 30px;
  border: 1px solid grey;
}

.filterBoxItem {
  width: 90%;
  padding: 0px 10px 0px 10px;
}

.filterBoxItemTitle {
  width: 90%;
  padding: 0px 10px 0px 10px;
  margin: 10px 0px 5px 10px;
}

.closebtn {
  margin-top: 20px;
  font-size: 35px !important;
  color: #818181;
  display: block;
  transition: 0.3s !important;
  right: 0;
  cursor: pointer;
}

.filterBox .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}