.searchbar {
  height: 50x !important;
  width: 500px;
}

.filterItem {
  width: 250px;
}

.deleteItem {
  cursor: pointer;
  margin: 10px;
}