
  
  /* Label Styles */
  label {
    font-weight: bold;
    margin-bottom: 4px;
    padding: 0 10px;

  }
  
  /* Select Styles */
  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
  }
  
  /* Hover Effect */
  select:hover {
    border-color: #555;
  }
  